/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath, useHistory, Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { FaTachometerAlt } from "react-icons/fa";
import { IoLogOutOutline } from "react-icons/io5";
import { GiToken, GiTrade } from "react-icons/gi";
import NavItem from "./NavItem";
import PeopleIcon from "@material-ui/icons/People";
import DashboardIcon from "@material-ui/icons/Dashboard";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import GiCircuitry from "@material-ui/icons/Info";

const sections = [
  {
    items: [
      {
        title: "Home",
        icon: FaTachometerAlt,
        href: "/",
      },
      {
        title: "Event-Management",
        icon: DashboardIcon,
        href: "/eventmanagement",
      },
      {
        title: "Practice-Management",
        icon: DashboardIcon,
        href: "/practice-management",
      },
      {
        title: "Garage-Management",
        icon: GiToken,
        href: "/garagemanagement",
      },
      {
        title: "User-Management",
        icon: PeopleIcon,
        href: "./usermanagemant",
      },
      {
        title: "Gallary-Management",
        icon: RecentActorsIcon,
        href: "/gallarymanagement",
      },
      {
        title: "Partners-Management",
        icon: SupervisorAccountIcon,
        href: "/partnersmanagement",
      },
      // {
      //   title: "Circuit-Management",
      //   icon: GiCircuitry,
      //   href: "/Circuitmanagement",
      // },
      {
        title: "Static-Management",
        icon: GiCircuitry,
        href: "/static-management",
      },
      {
        title: "Social-Management",
        icon: GiCircuitry,
        href: "/social-management",
      },
    ],
  },
];
const sectionsBelow = [
  {
    items: [],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#242538",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: "rgb(3, 10, 32)",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },
  logoutbutton: {
    justifyContent: "space-between",
    paddingLeft: 10,
    borderRadius: 0,
    width: "60px",
    textAlign: "center",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const logoutHandler = () => {
    history.push("/login");
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("userType");
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden mdDown>
        <Box padding={0} className={classes.logoicon}>
          <Link to="/">
            <Logo
              width="180"
              style={{
                width: "90px",
                cursor: "pointer",
              }}
            />
          </Link>
        </Box>
      </Hidden>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={2}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Box className="side_nev_Bottom">
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {section.items.map((itemList, i) => {
                return (
                  <Box
                    align="left"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Button
                      fullWidth
                      color="primary"
                      startIcon={
                        <IoLogOutOutline
                          style={{
                            fontSize: "30px",
                            color: "rgb(90, 134, 255)",
                          }}
                        />
                      }
                      key={i}
                      // className={classes.logoutbutton}
                      // style={{}}
                      // onClick={handleClickOpen}
                    >
                      {itemList.title}
                    </Button>
                    {/* <ThemeButton /> */}
                    <Dialog
                      open={open}
                      fullWidth
                      maxWidth="sm"
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Logout"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          align="center"
                          style={{ color: "#000" }}
                        >
                          Are you sure you want to logout?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={logoutHandler}
                          variant="contained"
                          size="large"
                          color="primary"
                          style={{ backgroundColor: "rgb(90 134 255)" }}
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={handleClose}
                          autoFocus
                          variant="contained"
                          size="large"
                          color="primary"
                          style={{ backgroundColor: "rgb(90 134 255)" }}
                        >
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                );
              })}

              {/* {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })} */}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
