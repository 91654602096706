import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";
import { toast } from "react-toastify";
import MetaMaskPopup from "src/component/MetaMaskPopup";
import { IconButton, Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Apiconfigs from "src/apiConfig/config";
import axios from "axios";
export const UserContext = createContext();

const setSession = (userAddress, token) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate } = useWeb3React();
  const [openmetamask, setOpenMetaMask] = React.useState(false);
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);

  //walletConnectFuntion
  const connectToWallet = () => {
    const connector = injected;
    window.sessionStorage.removeItem("walletName");
    window.sessionStorage.setItem("walletName", "METAMASK");
    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }
    activate(connector, undefined, true).catch((error) => {
      if (error) {
        console.log("error", error.message);
        // setErrorMsg(error.message + " Please install " + data.name);
        activate(connector);
        // setIsLoading(false);
        // setErrorPop(true);
      }
    });
  };

  const connectWalletHandler = async (walletAddress) => {
    try {
      const res = await axios.post(Apiconfigs.connectWallet, {
        walletAddress,
      });
      if (res.status === 200) {
        setTokenSession(res.data.data.token);
        getProfileHandler(res.data.data.token);
      } else {
        deactivate();
      }
    } catch (error) {
      deactivate();
    }
  };

  const getProfileHandler = async (token) => {
    setLoading(true);
    try {
      const res = await axios.get(Apiconfigs.profile, {
        headers: {
          token,
        },
      });
      console.log("res--", res);
      if (res.data.code === 200) {
        setUserData(res.data.data);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  //function to check wheather the existing network is same as the application enviornment or not
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  //function to change te existing network to make it same as the application
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };
  const diconnectWalletHandler = async () => {
    try {
      sessionStorage.removeItem("token");
      deactivate();
    } catch (error) {
      console.log(error);
    }
  };
  let data = {
    userData,
    loading,
    updateUser: (account) => {
      setSession(account);
    },
    connectWalletPopUp: () => {
      setOpenMetaMask(true);
    },
    connectWallet: () => connectToWallet(),
    disconnectWallet: () => diconnectWalletHandler(),
    getProfileHandler: (data) => getProfileHandler(data),
  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  useEffect(() => {
    if (account) {
      setOpenMetaMask(false);
      connectWalletHandler(account);
    }
  }, [account]);

  return (
    <UserContext.Provider value={data}>
      {props.children}
      {openmetamask && (
        <Dialog
          open={openmetamask}
          fullWidth
          maxWidth="xs"
          onClose={() => setOpenMetaMask(false)}
        >
          <IconButton
            style={{
              top: "-42px",
              color: "#fff",
              right: "-9px",
              position: "absolute",
            }}
            onClick={() => setOpenMetaMask(false)}
          >
            <CloseIcon style={{ color: "#FFFFFF" }} />
          </IconButton>
          <DialogContent>
            <MetaMaskPopup user={data} account={account} />
          </DialogContent>
        </Dialog>
      )}
    </UserContext.Provider>
  );
}
