import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  Divider,
  IconButton,
  ListItem,
  Link,
  DialogContent,
  DialogTitle,
  DialogActions,
  withStyles,
} from "@material-ui/core";
import { FiFacebook } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import MuiContent from "@material-ui/core/Dialog";
import { Link as RouterLink } from "react-router-dom";
import { FiSend, FiTwitter } from "react-icons/fi";
import Logo from "src/component/Logo";
import { useHistory } from "react-router-dom";
import PrivacyAndPolicy from "../../views/pages/StaticPages/PrivacyAndPolicy";
import TermsAndConditions from "../../views/pages/StaticPages/TermsAndConditions";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
   /*  padding: "30px 0px", */
    backgroundImage: "url('images/FooterBackgroundImage.png')",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .IconsBox": {
      /* padding: "15px 0px 30px", */
      display: "flex",
      alignItems: "center",
      maxWidth: "300px",
      justifyContent: "space-between",
      marginBottom:"16px",
      "& a": {
        "& svg": {
          color: "#00FFFF",
        },
      },
    },
    "& .footerLinkPages": {
      display: "flex",
      alignItems: "center",
      justifyContent: "inherit",
      zIndex: 999,
      position: "relative",
      "& h4": {
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        "& span": {
          color: "#959595",
          cursor: "pointer",
          display: "flex",
          width: "max-content",
        },
      },
    },
  },
}));
const Dialog = withStyles((theme) => ({
  MuiDialog: {
    paperWidthSm: {
      maxWidth: "1200px !important",
    },
  },
}))(MuiContent);

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();
  const [privacyAndPolicy, setPrivacyAndPolicy] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);

  return (
    <>
      <Box className={classes.root}>
        <Box align="center" mt={3} mb={2}>
          <RouterLink to="/">
            <Logo className="logoImg" />
          </RouterLink>
        </Box>
        <Box className="IconsBox">
          <IconButton target="_blank" href="https://www.facebook.com/">
            <FiFacebook />
          </IconButton>
          <IconButton target="_blank" href="https://www.instagram.com/">
            <FaInstagram />
          </IconButton>
          <IconButton target="_blank" href="https://telegram.org/">
            <FiSend />
          </IconButton>
          <IconButton target="_blank" href="https://twitter.com/">
            <FiTwitter />
          </IconButton>
          <IconButton target="_blank" href="https://twitter.com/">
            <img src="images/MIcon.svg" />
          </IconButton>
        </Box>
        <Container maxWidth="lg">
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
          <Box className="footerLinkPages">
            <Typography variant="h4">
              <span onClick={() => history.push("/about")}>About</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span onClick={() => history.push("/media")}>Media</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span onClick={() => setPrivacyAndPolicy(true)}>Privacy</span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span onClick={() => setTermsAndConditions(true)}>
                Terms and Conditions
              </span>
            </Typography>
          </Box>
          <Box align="center" my={3}>
            <Typography variant="body1" style={{ color: "#959595" }}>
              Copyright © 2022 Cash Racer, All Rights Reserved.
            </Typography>
          </Box>
        </Container>
      </Box>
      <Dialog
        onClose={() => setPrivacyAndPolicy(false)}
        aria-labelledby="customized-dialog-title"
        open={privacyAndPolicy}
        maxWidth="lg"
      >
        <IconButton onClick={() => setPrivacyAndPolicy(false)}>
          <CloseIcon
            style={{
              top: "-60px",
              color: "#fff",
              right: "-30px",
              position: "absolute",
            }}
          />
        </IconButton>

        <DialogContent>
          <Box>
            <PrivacyAndPolicy />
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => setTermsAndConditions(false)}
        aria-labelledby="customized-dialog-title"
        open={termsAndConditions}
        maxWidth="lg"
      >
        <IconButton onClick={() => setTermsAndConditions(false)}>
          <CloseIcon
            style={{
              top: "-60px",
              color: "#fff",
              right: "-30px",
              position: "absolute",
            }}
          />
        </IconButton>

        <DialogContent>
          <Box>
            <TermsAndConditions />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
